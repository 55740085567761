@keyframes slide-in-right {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.filters1 {
  position: relative;
  font-weight: 600;
}
.filtersWrapper {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.days {
  position: relative;
  font-size: var(--paragraph-size);
  font-family: var(--h5);
  color: var(--color-white);
  text-align: left;
}
.daysContainer,
.daysWrapper {
  cursor: pointer;
  padding: var(--padding-3xs);
  align-self: stretch;
  border-radius: var(--br-3xs);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.daysWrapper {
  border: 0;
  background-color: var(--red);
}
.daysContainer {
  border: 2px solid var(--red);
  background-color: transparent;
}
.months {
  position: relative;
  font-size: var(--paragraph-size);
  font-family: var(--h5);
  color: var(--black);
  text-align: left;
}
.filters,
.frameParent {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent {
  align-self: stretch;
  gap: var(--gap-3xs);
}
.filters {
  position: relative;
  background-color: var(--color-white);
  width: 195px;
  padding: var(--padding-21xl) var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-xl);
  opacity: 0;
  height: 100%;
  max-width: 90%;
  text-align: left;
  font-size: var(--h5-size);
  color: var(--black);
  font-family: var(--h5);
}
.filters.animate {
  animation: 0.25s ease 0s 1 normal forwards slide-in-right;
}

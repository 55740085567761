.frameChild {
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 20px;
  border-radius: var(--br-mini);
  width: 187px;
  height: 184px;
  z-index: 0;
}
.frameItem,
.joshIcon {
  border-radius: var(--br-81xl);
}
.frameItem {
  position: absolute;
  margin: 0 !important;
  top: -7px;
  left: 31px;
  background-color: var(--off-white);
  width: 114px;
  height: 114px;
  z-index: 0;
}
.joshIcon {
  position: relative;
  width: 100px;
  height: 100px;
  object-fit: cover;
  z-index: 1;
}
.joshuaBroome {
  position: relative;
  display: inline-block;
  width: 177px;
  z-index: 2;
}
.rectangleParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-8xs);
  text-align: center;
}
.houseIcon {
  width: 15px;
  height: 14px;
}
.california,
.houseIcon {
  position: relative;
}
.houseParent {
  width: 159px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-8xs);
}
.calendarAltIcon,
.usersIcon {
  position: relative;
  width: 16px;
  height: 14px;
}
.calendarAltIcon {
  height: 16px;
}
.frameGroup {
  gap: var(--gap-5xs);
}
.frameGroup,
.frameWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.basedOutOf {
  align-self: stretch;
  position: relative;
  font-size: var(--label-size);
}
.bioParent,
.frameContainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameContainer {
  overflow: hidden;
}
.frameParent,
.vectorParent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.frameParent {
  align-items: center;
  gap: var(--gap-lg);
  z-index: 1;
}
.vectorParent {
  align-self: stretch;
  border-radius: 0 var(--br-3xs) var(--br-3xs) 0;
  width: 217px;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-xl);
  box-sizing: border-box;
  align-items: flex-start;
  position: relative;
  gap: var(--gap-mini);
}
.distribution {
  position: relative;
  font-weight: 600;
}
.distributionWrapper {
  width: 413px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.cell {
  width: 161px;
  overflow: hidden;
  flex-shrink: 0;
}
.cell1 {
  width: 70px;
  flex-shrink: 0;
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
}
.cell1,
.cell2,
.cell3 {
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-3xs);
  align-items: center;
}
.cell2 {
  width: 106px;
  flex-shrink: 0;
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
}
.cell3 {
  flex: 1;
}
.cell3,
.cell4,
.cell5,
.cell6,
.row {
  display: flex;
  justify-content: flex-start;
}
.row {
  border-bottom: 1px solid var(--grey);
  box-sizing: border-box;
  width: 440px;
  height: 43px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  padding: var(--padding-8xs) 0;
  align-items: flex-start;
}
.cell4,
.cell5,
.cell6 {
  align-self: stretch;
  align-items: center;
}
.cell4 {
  background-color: var(--off-white);
  width: 161px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-xl) var(--padding-3xs)
    var(--padding-3xs);
  box-sizing: border-box;
}
.cell5,
.cell6 {
  padding: var(--padding-3xs);
}
.cell5 {
  width: 70px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  box-sizing: border-box;
}
.cell6 {
  width: 106px;
}
.cell6,
.cell7,
.row1 {
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
}
.cell7 {
  align-self: stretch;
  width: 169px;
  display: flex;
  padding: var(--padding-3xs);
  align-items: center;
  justify-content: flex-start;
}
.row1 {
  border-bottom: 1px solid var(--color-whitesmoke-100);
  width: 440px;
  height: 44px;
}
.frameParent1,
.row1,
.row2,
.table {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.row2 {
  border-bottom: 1px solid var(--color-whitesmoke-100);
  box-sizing: border-box;
  width: 440px;
  height: 43px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
}
.frameParent1,
.table {
  align-self: stretch;
  flex-direction: column;
}
.table {
  border-radius: var(--br-8xs);
  background-color: var(--white);
  border: 1px solid var(--grey);
  overflow-x: auto;
  font-size: var(--paragraph-size);
}
.frameParent1 {
  gap: var(--gap-3xs);
}
.cell12,
.cell14 {
  width: 155px;
  overflow: hidden;
  flex-shrink: 0;
}
.cell14 {
  width: 94px;
  flex-direction: row;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
}
.cell14,
.cell16,
.row3 {
  display: flex;
  justify-content: flex-start;
}
.row3 {
  border-bottom: 1px solid var(--grey);
  box-sizing: border-box;
  width: 440px;
  height: 44px;
  flex-direction: row;
  padding: var(--padding-8xs) 0;
  align-items: flex-start;
}
.cell16 {
  align-self: stretch;
  background-color: var(--off-white);
  width: 155px;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-3xs) var(--padding-xl) var(--padding-3xs)
    var(--padding-3xs);
  align-items: center;
}
.cell16,
.cell18,
.row4 {
  box-sizing: border-box;
  flex-direction: row;
}
.cell18 {
  align-self: stretch;
  width: 94px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  padding: var(--padding-3xs);
  align-items: center;
  justify-content: flex-start;
}
.row4 {
  border-bottom: 1px solid var(--color-whitesmoke-100);
  width: 440px;
  height: 44px;
  align-items: flex-start;
}
.cell30,
.frameDiv,
.popupJoshua,
.row4 {
  display: flex;
  justify-content: flex-start;
}
.cell30 {
  align-self: stretch;
  width: 84px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
}
.frameDiv,
.popupJoshua {
  align-items: flex-start;
}
.frameDiv {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  padding: var(--padding-11xl);
  gap: var(--gap-xl);
  font-size: var(--h5-size);
}
.popupJoshua {
  position: relative;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  width: 717px;
  overflow-y: auto;
  flex-direction: row;
  padding: var(--padding-3xs) 0;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  text-align: left;
  font-size: var(--paragraph-size);
  color: var(--black);
  font-family: var(--h5);
}
@media screen and (max-width: 960px) {
  .frameChild {
    display: none;
    align-self: stretch;
    width: auto;
  }
  .frameGroup {
    align-items: center;
    justify-content: flex-start;
  }
  .frameGroup,
  .frameWrapper {
    align-self: stretch;
    width: auto;
    flex-direction: column;
  }
  .vectorParent {
    width: 100%;
  }
  .frameDiv {
    flex: unset;
    align-self: stretch;
  }
  .popupJoshua {
    flex-direction: column;
  }
}
@media screen and (max-width: 420px) {
  .frameGroup {
    width: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

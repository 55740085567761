.portalPopupOverlay {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.modalCloseBtn {
  position: absolute;
}

@font-face {
  font-family: "Noir Pro";
  src: url("./noirprobold.ttf") format("truetype");
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: "Noir Pro";
  src: url("./noirprosemibold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Noir Pro";
  src: url("./noirproregular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --h5: "Noir Pro";

  /* font sizes */
  --paragraph-size: 16px;
  --h5-size: 20px;
  --label-size: 14px;
  --h4-size: 30px;
  --h2-size: 40px;

  /* Colors */
  --color-white: #fff;
  --red: #c2453f;
  --color-brown-100: rgba(194, 69, 62, 0.08);
  --white: #fefefe;
  --black: #222;
  --grey: #d6d6d6;
  --off-white: #f9f9f9;
  --color-whitesmoke-100: #efefef;

  /* Gaps */
  --gap-xl: 20px;
  --gap-3xs: 10px;
  --gap-mini: 15px;
  --gap-lg: 18px;
  --gap-5xs: 8px;
  --gap-8xs: 5px;

  /* Paddings */
  --padding-21xl: 40px;
  --padding-xl: 20px;
  --padding-3xs: 10px;
  --padding-11xl: 30px;
  --padding-8xs: 5px;
  --padding-31xl: 50px;
  --padding-mini: 15px;

  /* border radiuses */
  --br-3xs: 10px;
  --br-8xs: 5px;
  --br-31xl: 50px;
  --br-81xl: 100px;
  --br-mini: 15px;
}

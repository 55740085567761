.vectorIcon {
  position: fixed;
  margin: 0 !important;
  top: 321px;
  left: 0;
  width: 100%;
  height: 523px;
  object-fit: cover;
  z-index: 1;
}
.shareTheKing1Icon {
  position: relative;
  width: 53px;
  height: 75px;
  object-fit: cover;
}
.metricsLeaderboard {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 600;
  font-family: inherit;
}
.shareTheKing1Parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.clockIcon {
  position: relative;
  width: 16px;
  height: 16px;
}
.amCdt {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
}
.clockParent,
.dashboardInner,
.frameParent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.clockParent {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-8xs);
  margin-top: -3px;
  font-size: var(--paragraph-size);
}
.dashboardInner,
.frameParent {
  flex-direction: column;
}
.dashboardInner {
  align-self: stretch;
  padding: var(--padding-3xs);
}
.filters {
  position: relative;
  font-weight: 600;
}
.filtersWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.slidersDashboardIcon {
  position: relative;
  width: 30px;
  height: 28.86px;
  object-fit: cover;
  cursor: pointer;
}
.mobileFilters {
  cursor: pointer;
  border: 0;
  padding: var(--padding-3xs);
  background-color: transparent;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.days {
  position: relative;
  font-size: var(--paragraph-size);
  font-family: var(--h5);
  color: var(--color-white);
  text-align: left;
}
.daysContainer,
.daysWrapper {
  cursor: pointer;
  padding: var(--padding-3xs);
  border-radius: var(--br-3xs);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.daysWrapper {
  border: 0;
  background-color: var(--red);
}
.daysContainer {
  border: 1px solid var(--red);
  background-color: transparent;
}
.customWrapper:hover,
.daysContainer:hover,
.monthsWrapper:hover {
  background-color: var(--red);
}
.customWrapper:hover .months,
.daysContainer:hover .months,
.monthsWrapper:hover .months {
  color: var(--color-white);
}
.months {
  position: relative;
  font-size: var(--paragraph-size);
  font-family: var(--h5);
  color: var(--black);
  text-align: left;
}
.customWrapper,
.frameDiv,
.monthsWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.customWrapper,
.monthsWrapper {
  cursor: pointer;
  border: 1px solid var(--red);
  padding: var(--padding-3xs);
  background-color: transparent;
  border-radius: var(--br-3xs);
  justify-content: flex-start;
}
.frameDiv {
  justify-content: flex-end;
  gap: var(--gap-xl);
}
.cell,
.frameContainer {
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
.frameContainer {
  align-self: stretch;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  padding: var(--padding-mini) 0;
  align-items: center;
  justify-content: space-between;
}
.cell {
  width: 100px;
  flex-shrink: 0;
  padding: var(--padding-3xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}
.caretDownIcon {
  position: relative;
  width: 10.59px;
  height: 6.38px;
}
.cell1 {
  width: 182px;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-3xs) var(--padding-xl);
  align-items: center;
  gap: var(--gap-8xs);
}
.cell1,
.cell2,
.cell3 {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: flex-start;
}
.cell2 {
  width: 129px;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-3xs) var(--padding-xl);
  align-items: center;
  gap: var(--gap-8xs);
}
.cell3 {
  width: 221px;
}
.cell3,
.cell4,
.cell5 {
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-3xs) var(--padding-xl);
  align-items: center;
  gap: var(--gap-8xs);
}
.cell4 {
  width: 223px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: flex-start;
}
.cell5 {
  width: 105px;
}
.cell5,
.cell6,
.row {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: flex-start;
}
.cell6 {
  width: 127.59px;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-3xs) var(--padding-xl);
  align-items: center;
  gap: var(--gap-8xs);
}
.row {
  width: 100%;
  padding: var(--padding-8xs) 0;
  align-items: flex-start;
  font-size: var(--h5-size);
}
.marcusIcon {
  position: relative;
  border-radius: var(--br-31xl);
  width: 57px;
  height: 57px;
  object-fit: cover;
}
.cell7 {
  text-decoration: none;
  align-self: stretch;
  background-color: var(--off-white);
  width: 100px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.marcusStanley {
  position: relative;
}
.cell10,
.cell11,
.cell12,
.cell13,
.cell8,
.cell9 {
  align-self: stretch;
  width: 182px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
}
.cell10,
.cell11,
.cell12,
.cell13,
.cell9 {
  width: 129px;
}
.cell10,
.cell11,
.cell12,
.cell13 {
  width: 221px;
}
.cell11,
.cell12,
.cell13 {
  width: 223px;
}
.cell12,
.cell13 {
  width: 105px;
}
.cell13 {
  width: 128px;
}
.row1 {
  text-decoration: none;
  border-top: 1px solid var(--color-whitesmoke-100);
  border-bottom: 1px solid var(--color-whitesmoke-100);
  width: 1382px;
  height: 82px;
  align-items: flex-start;
  cursor: pointer;
  color: inherit;
}
.row1:hover,
.row2:hover,
.row3:hover {
  background-color: var(--color-brown-100);
}
.cell20,
.cell21,
.row1,
.row2 {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: flex-start;
}
.cell20 {
  align-self: stretch;
  width: 126px;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-3xs) var(--padding-xl);
  align-items: center;
}
.cell21,
.row2 {
  cursor: pointer;
}
.row2 {
  text-decoration: none;
  border-bottom: 1px solid var(--color-whitesmoke-100);
  width: 1382px;
  height: 82px;
  align-items: flex-start;
  color: inherit;
}
.cell21 {
  align-self: stretch;
  background-color: var(--off-white);
  width: 100px;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--padding-3xs) var(--padding-xl);
  align-items: center;
}
.dashboard,
.frameGroup,
.row3,
.table {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.row3 {
  text-decoration: none;
  border-bottom: 1px solid var(--color-whitesmoke-100);
  box-sizing: border-box;
  width: 1382px;
  height: 82px;
  flex-direction: row;
  cursor: pointer;
  color: inherit;
}
.dashboard,
.frameGroup,
.table {
  flex-direction: column;
}
.table {
  align-self: stretch;
  border-radius: var(--br-3xs);
  background-color: var(--white);
  border: 1px solid var(--grey);
  overflow-x: auto;
  font-size: var(--paragraph-size);
}
.dashboard,
.frameGroup {
  position: relative;
}
.frameGroup {
  align-self: stretch;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  padding: var(--padding-xl);
  gap: var(--gap-3xs);
  z-index: 2;
  font-size: var(--h4-size);
}
.dashboard {
  background-color: var(--off-white);
  width: 100%;
  padding: var(--padding-21xl) var(--padding-31xl);
  box-sizing: border-box;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--h2-size);
  color: var(--black);
  font-family: var(--h5);
}
@media screen and (max-width: 1200px) {
  .row {
    align-self: stretch;
    width: auto;
  }
  .row2 {
    width: 100%;
  }
}
@media screen and (max-width: 960px) {
  .metricsLeaderboard {
    text-align: center;
  }
  .shareTheKing1Parent {
    flex-direction: column;
  }
  .amCdt {
    text-align: center;
  }
  .dashboardInner,
  .frameParent {
    flex-direction: column;
  }
  .mobileFilters {
    display: flex;
  }
  .frameDiv {
    display: none;
  }
  .dashboard {
    padding-left: var(--padding-mini);
    padding-right: var(--padding-mini);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 420px) {
  .mobileFilters {
    display: flex;
  }
  .frameDiv {
    display: none;
  }
  .frameContainer {
    flex-direction: row;
  }
}
